﻿/*
 *  Document   : _infobox.scss
 *  Author     : RedStar Template
 *  Description: This scss file for info box style classes
 */
.info-box {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  height: 80px;
  display: flex;
  cursor: default;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;

  .icon {
    display: inline-block;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.12);
    width: 80px;

    i {
      color: #fff;
      font-size: 50px;
      line-height: 80px;
    }

    .chart.chart-bar {
      height: 100%;
      line-height: 100px;

      canvas {
        vertical-align: baseline !important;
      }
    }

    .chart.chart-pie {
      height: 100%;
      line-height: 123px;

      canvas {
        vertical-align: baseline !important;
      }
    }

    .chart.chart-line {
      height: 100%;
      line-height: 115px;

      canvas {
        vertical-align: baseline !important;
      }
    }
  }

  .content {
    display: inline-block;
    padding: 7px 10px;

    .text {
      font-size: 13px;
      margin-top: 11px;
      color: #555;
    }

    .number {
      font-weight: normal;
      font-size: 26px;
      margin-top: -4px;
      color: #555;
    }
  }
}

.info-box.hover-zoom-effect {
  .icon {
    overflow: hidden;

    i {
      @include transition(all 0.3s ease);
    }
  }

  &:hover {
    .icon {
      i {
        opacity: 0.4;
        @include transform(rotate(-32deg) scale(1.4));
      }
    }
  }
}

.info-box.hover-expand-effect {
  &:after {
    background-color: rgba(0, 0, 0, 0.05);
    content: '.';
    position: absolute;
    left: 80px;
    top: 0;
    width: 0;
    height: 100%;
    color: transparent;
    @include transition(all 0.95s);
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }
}

.info-box-2 {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  height: 80px;
  display: flex;
  cursor: default;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;

  .icon {
    display: inline-block;
    text-align: center;
    width: 80px;

    i {
      color: #fff;
      font-size: 50px;
      line-height: 80px;
    }
  }

  .chart.chart-bar {
    height: 100%;
    line-height: 105px;

    canvas {
      vertical-align: baseline !important;
    }
  }

  .chart.chart-pie {
    height: 100%;
    line-height: 123px;

    canvas {
      vertical-align: baseline !important;
    }
  }

  .chart.chart-line {
    height: 100%;
    line-height: 115px;

    canvas {
      vertical-align: baseline !important;
    }
  }

  .content {
    display: inline-block;
    padding: 7px 10px;

    .text {
      font-size: 13px;
      margin-top: 11px;
      color: #555;
    }

    .number {
      font-weight: normal;
      font-size: 26px;
      margin-top: -4px;
      color: #555;
    }
  }
}

.info-box-2.hover-zoom-effect {
  .icon {
    overflow: hidden;

    i {
      @include transition(all 0.3s ease);
    }
  }

  &:hover {
    .icon {
      i {
        opacity: 0.4;
        @include transform(rotate(-32deg) scale(1.4));
      }
    }
  }
}

.info-box-2.hover-expand-effect {
  &:after {
    background-color: rgba(0, 0, 0, 0.05);
    content: '.';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    color: transparent;
    @include transition(all 0.95s);
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }
}

.info-box-3 {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  height: 80px;
  display: flex;
  cursor: default;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;

  .icon {
    position: absolute;
    right: 10px;
    bottom: 2px;
    text-align: center;

    i {
      color: rgba(0, 0, 0, 0.15);
      font-size: 60px;
    }
  }

  .chart {
    margin-right: 5px;
  }

  .chart.chart-bar {
    height: 100%;
    line-height: 50px;

    canvas {
      vertical-align: baseline !important;
    }
  }

  .chart.chart-pie {
    height: 100%;
    line-height: 34px;

    canvas {
      vertical-align: baseline !important;
    }
  }

  .chart.chart-line {
    height: 100%;
    line-height: 40px;

    canvas {
      vertical-align: baseline !important;
    }
  }

  .content {
    display: inline-block;
    padding: 7px 16px;

    .text {
      font-size: 13px;
      margin-top: 11px;
      color: #555;
    }

    .number {
      font-weight: normal;
      font-size: 26px;
      margin-top: -4px;
      color: #555;
    }
  }
}

.info-box-3.hover-zoom-effect {
  .icon {
    i {
      @include transition(all 0.3s ease);
    }
  }

  &:hover {
    .icon {
      i {
        opacity: 0.4;
        @include transform(rotate(-32deg) scale(1.4));
      }
    }
  }
}

.info-box-3.hover-expand-effect {
  &:after {
    background-color: rgba(0, 0, 0, 0.05);
    content: '.';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    color: transparent;
    @include transition(all 0.95s);
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }
}

.info-box-4 {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  height: 80px;
  display: flex;
  cursor: default;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;

  .icon {
    position: absolute;
    right: 10px;
    bottom: 2px;
    text-align: center;

    i {
      color: rgba(0, 0, 0, 0.15);
      font-size: 60px;
    }
  }

  .chart {
    margin-right: 5px;
  }

  .chart.chart-bar {
    height: 100%;
    line-height: 50px;

    canvas {
      vertical-align: baseline !important;
    }
  }

  .chart.chart-pie {
    height: 100%;
    line-height: 34px;

    canvas {
      vertical-align: baseline !important;
    }
  }

  .chart.chart-line {
    height: 100%;
    line-height: 40px;

    canvas {
      vertical-align: baseline !important;
    }
  }

  .content {
    display: inline-block;
    padding: 7px 16px;

    .text {
      font-size: 13px;
      margin-top: 11px;
      color: #555;
    }

    .number {
      font-weight: normal;
      font-size: 26px;
      margin-top: -4px;
      color: #555;
    }
  }
}

.info-box-4.hover-zoom-effect {
  .icon {
    i {
      @include transition(all 0.3s ease);
    }
  }

  &:hover {
    .icon {
      i {
        opacity: 0.4;
        @include transform(rotate(-32deg) scale(1.4));
      }
    }
  }
}

.info-box-4.hover-expand-effect {
  &:after {
    background-color: rgba(0, 0, 0, 0.05);
    content: '.';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    color: transparent;
    @include transition(all 0.95s);
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }
}
.info-box-new {
  background: #fff;
  padding: 20px 20px 0px 20px;
  color: #463f3f;
  border-radius: 15px;

  .progress {
    height: 10px;
    border-radius: 20px;
  }
}
.support-box {
  padding: 15px;
  color: #fff;
  margin: 8px 0px 25px 0px;
  border-radius: 10px;
  min-height: 140px;
}
.counter-box {
  padding: 15px;
  color: #212529;
  margin: 8px 0px 25px 0px;
  border-radius: 10px;
  min-height: 140px;
  background-color: #ffffff;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
}
.info-box1 .text-end h2 {
  color: #44e229;
}
.info-box2 .text-end h2 {
  color: #e66c2f;
}
.info-box3 .text-end h2 {
  color: #58b5f5;
}
.info-box4 .text-end h2 {
  color: #ab92d4;
}

.info-box5 {
  width: 100%;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  margin-bottom: 25px;
  padding: 0px 20px 0 20px;
  border-radius: 25px;
  height: 110px;
  display: flex;
  cursor: default;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  small {
    font-size: 14px;
  }
  .progress {
    background: rgba(0, 0, 0, 0.2);
    margin: 5px -10px 5px 0;
    height: 8px;
    background: #e3e3e3;
    border-radius: 20px;
    box-shadow: none;
    overflow: visible;
    .progress-bar {
      border-radius: 20px;
      background: #fff;
    }
  }
  .knob-icon {
    margin-top: 16px;
  }
  .info-box-content {
    margin-top: 24px;
    margin-left: 10px;
  }
  .progress-bar {
    position: relative;
    animation: animate-positive 4s;
    line-height: 8px;
  }
}
.info-box6 {
  box-shadow: 2px 2px 10px #dadada;
  margin: 8px 0px 25px 0px;
  padding: 20px 10px;
  background-color: #fff;
  height: 100px;
  border-radius: 5px;
  transition: 0.3s linear all;
  &:hover {
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: 4px 4px 20px #dadada;
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: 0.3s linear all;
  }
  &.primary {
    background-color: #007bff;
    color: #fff;
  }
  &.danger {
    background-color: #ef5350;
    color: #fff;
  }
  &.success {
    background-color: #66bb6a;
    color: #fff;
  }
  &.info {
    background-color: #26c6da;
    color: #fff;
  }
  i {
    font-size: 5em;
    opacity: 0.2;
  }
  .count-numbers {
    position: absolute;
    right: 35px;
    top: 20px;
    font-size: 32px;
    display: block;
  }
  .count-name {
    position: absolute;
    right: 35px;
    top: 65px;
    font-style: italic;
    text-transform: capitalize;
    opacity: 0.5;
    display: block;
    font-size: 18px;
  }
}
.bg-c-blue {
  background: linear-gradient(45deg, #4099ff, #73b4ff);
}
.bg-c-green {
  background: linear-gradient(45deg, #2ed8b6, #59e0c5);
}
.bg-c-yellow {
  background: linear-gradient(45deg, #ffb64d, #ffcb80);
}
.bg-c-pink {
  background: linear-gradient(45deg, #ff5370, #ff869a);
}
.info-box7 {
  border-radius: 5px;
  color: #fff;
  box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  border: none;
  margin: 8px 0px 25px 0px;
  transition: all 0.3s ease-in-out;
  .info-box7-block {
    padding: 25px;
  }
}
.order-info-box7 i {
  font-size: 26px;
}
.box-part {
  background: #fff;
  border-radius: 10px;
  padding: 30px 15px;
  margin: 5px 0 23px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
}
.infobox-5 {
  .card-icon {
    i {
      font-size: 4rem;
    }
  }
}
.card-statistic-4 {
  position: relative;
  color: #000000;
  padding: 15px;
  border-radius: 3px;
  overflow: hidden;

  .card-icon-large {
    font-size: 110px;
    width: 110px;
    height: 50px;
    text-shadow: 3px 7px rgba(0, 0, 0, 0.3);
  }

  .card-icon {
    text-align: center;
    line-height: 50px;
    margin-left: 15px;
    color: #000;
    position: absolute;
    right: -5px;
    top: 20px;
    opacity: 0.1;
  }

  .banner-img img {
    max-width: 100%;
    float: right;
  }
}
@-webkit-keyframes animate-positive {
  0% {
    width: 0;
  }
}
@keyframes animate-positive {
  0% {
    width: 0;
  }
}
