﻿/*
 *  Document   : _breadscrumbs.scss
 *  Author     : RedStar Template
 *  Description: This scss file for breadscrumbs style classes
 */

.breadcrumb {
  @include border-radius(0);
  background-color: transparent;
  font-size: 15px;
  margin-bottom: 0px;
  padding-bottom: 10px;
  color: #555;

  &:before {
    content: '';
    margin: 0;
  }

  li {
    display: inline-block;
    a {
      color: #444;
      text-decoration: none;

      .fa-home {
        font-size: 17px;
        position: relative;
        top: 0;
        float: left;
        padding-left: 10px;
        color: #444444;
      }
    }

    .material-icons {
      font-size: 18px;
      position: relative;
      top: 4px;
      float: none;
    }
    &.active {
      color: #444444;
    }
  }

  > li + li:before {
    content: '/' !important;
    color: #444444;
    padding-left: 5px;
  }
  .page-title {
    border-right: 1px solid #c5c5c5;
    padding-right: 10px;
    font-size: 23px;
    font-weight: 600;
    color: #444444;
  }
  .breadcrumb-item + .breadcrumb-item {
    padding-top: 3px;
    padding-left: 0px;
    &::before {
      float: unset;
      padding-right: 5px;
    }
  }
}

@each $key, $val in $colors {
  .breadcrumb-col-#{$key} {
    li {
      a {
        color: $val !important;
        font-weight: bold;
      }
    }
  }

  .breadcrumb-bg-#{$key} {
    background-color: $val !important;

    li {
      a {
        color: #fff;
        font-weight: bold;

        .material-icons {
          padding-bottom: 8px;
        }
      }

      color: #fff !important;
    }

    li + li:before {
      color: #fff;
    }
  }
}
.breadcrumb-style {
  border-radius: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.breadcrumb-chart {
  display: inline-block;
  .chart-info p {
    font-size: 13px;
  }
}
