:host ::ng-deep .ck-editor__editable_inline {
    min-height: 400px;
  }

  .xyz{
    min-height: 400px;
  }

  .icon-pos{
    margin-bottom: 13px
}

  /* loader starts */
.overlayDiv {
  width: 100vw;
  height: 100vh;
  display: block;
  position: fixed;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  -moz-backdrop-filter: blur(5px);
}
.pleaseWait{
  position: absolute;
  top: 57%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.loader {
  position: absolute;
  top: calc(50% - 6vmax);
  left: calc(50% - 4vmax);
  width: 8vmax;
  height: 8vmax;
  border-right: 4px solid #ffffff;
  border-radius: 100%;
  animation: spinRight 1s linear infinite;
}
    
.loader:before{
  content: '';
  width: 6vmax;
  height: 6vmax;
  display: block;
  position: absolute;
  top: calc(50% - 3vmax);
  left: calc(50% - 3vmax);
  border-left: 3px solid #ffffff;
  border-radius: 100%;
  animation: spinLeft 1s linear infinite;
}
    
.loader:after {
  content: '';
  width: 6vmax;
  height: 6vmax;
  display: block;
  position: absolute;
  top: calc(50% - 3vmax);
  left: calc(50% - 3vmax);
  border-left: 3px solid #ffffff;
  border-radius: 100%;
  animation: spinLeft 800ms linear infinite;
}
  
@keyframes spinLeft {
  from {transform:rotate(0deg);}
  to {transform:rotate(720deg);}
}

@keyframes spinRight {
  from {transform:rotate(360deg);}
  to {transform:rotate(0deg);}
}

/* loader end */

body.light{
  overflow: revert !important;
}

.multi-product input{
  width: -webkit-fill-available;
}
.multi-product label{
  margin-left: 8px;
}