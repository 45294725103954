﻿/*
 *  Document   : _general.scss
 *  Author     : RedStar Template
 *  Description: This scss file for all common style classes
 */

body,
html {
  @include transition(all 0.5s);
  background-color: #f0f3fb;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}

button,
input,
select,
a {
  outline: none !important;
  font-size: 14px !important;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

textarea {
  font-size: 14px !important;
}

.row {
  margin-bottom: 0px !important;
}

ol,
ul,
dl {
  padding-left: 0px;
  list-style-type: none;
}

.no-animate {
  -o-transition-property: none !important;
  -moz-transition-property: none !important;
  -ms-transition-property: none !important;
  -webkit-transition-property: none !important;
  transition-property: none !important;
  -o-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -webkit-transform: none !important;
  transform: none !important;
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}

section {
  &.content {
    margin: 75px 15px 0 275px;
    min-height: calc(100vh - 76px);
    @include transition(0.5s);
  }
}

.horizontal-layout {
  section {
    &.content {
      margin: 170px 15px 0 15px;
      float: left;
      width: calc(100% - 30px);
    }
  }
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.msl-1 {
  margin-left: 0.25rem !important;
}
.msl-2 {
  margin-left: 0.5rem !important;
}
.msl-3 {
  margin-left: 1rem !important;
}
.msl-4 {
  margin-left: 1.5rem !important;
}
.msl-5 {
  margin-left: 3rem !important;
}

.msr-1 {
  margin-right: 0.25rem !important;
}
.msr-2 {
  margin-right: 0.5rem !important;
}
.msr-3 {
  margin-right: 1rem !important;
}
.msr-4 {
  margin-right: 1.5rem !important;
}
.msr-5 {
  margin-right: 3rem !important;
}

.psl-1 {
  padding-left: 0.25rem !important;
}
.psl-2 {
  padding-left: 0.5rem !important;
}
.psl-3 {
  padding-left: 1rem !important;
}
.psl-4 {
  padding-left: 1.5rem !important;
}
.psl-5 {
  padding-left: 3rem !important;
}

.psr-1 {
  padding-right: 0.25rem !important;
}
.psr-2 {
  padding-right: 0.5rem !important;
}
.psr-3 {
  padding-right: 1rem !important;
}
.psr-4 {
  padding-right: 1.5rem !important;
}
.psr-5 {
  padding-right: 3rem !important;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  width: 15px;
  height: 15px;
}

table {
  .checkbox {
    [type='checkbox'] + label {
      margin: 0;
      height: 20px;
      padding-left: 20px;
      vertical-align: middle;
    }
  }
}

.loading-img-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin: -60px 0 20px -20px;
  -webkit-animation: spin 1.5s linear infinite;
  -moz-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

.shadow-style {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.review-img {
  padding-left: 20px;
  padding-top: 5px;
  width: 70px;
  img {
    border-radius: 50%;
    border: 2px solid #ffffff;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  }
}

.horizontal-layout {
  .sidebar,
  .nav-left-menu {
    display: none;
  }
}

.bootstrap-notify-container {
  max-width: 320px;
  text-align: center;
}

.map iframe {
  width: 100%;
}

.jqvmap-label {
  position: absolute;
  display: none;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background: #eee;
  color: black;
  font-size: 14px;
  font-family: sans-serif, Verdana;
  padding: 10px;
  pointer-events: none;
}

.logo-white {
  .navbar-toggle,
  .bars:before,
  .bars:after {
    color: #000 !important;
  }
}

.logo-black {
  .navbar-toggle,
  .bars:before,
  .bars:after {
    color: #fff !important;
  }
}

.dark {
  .sidemenu-collapse i {
    color: #fff;
  }

  .nav {
    > li {
      > a {
        color: #fff;
      }
    }
  }
}

.light {
  .sidemenu-collapse i {
    color: #0d091d;
  }

  .nav {
    > li {
      > a {
        color: #0d091d;
      }
    }
  }
}

.border-apply {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2) !important;
}

input::-webkit-input-placeholder {
  font-size: 12px;
  color: #adabab;
}

input:-moz-placeholder {
  /* Firefox 18- */
  font-size: 12px;
  color: #adabab;
}

input::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px;
  color: #adabab;
}

input:-ms-input-placeholder {
  font-size: 12px;
  color: #adabab;
}

textarea::-webkit-input-placeholder {
  font-size: 12px;
  color: #adabab;
}

textarea:-moz-placeholder {
  /* Firefox 18- */
  font-size: 12px;
  color: #adabab;
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px;
  color: #adabab;
}

textarea:-ms-input-placeholder {
  font-size: 12px;
  color: #adabab;
}

.profile-image img {
  width: 100%;
}

.rating-star {
  display: flex;
  align-items: center;
}

.font-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  transition: all 0.2s;

  .icon-preview {
    font-size: 1.8rem;
    margin-right: 10px;
    line-height: 1;
    color: #333439;
  }
}

.deshboard-echart-height {
  height: 350px;
}

.desktop-pie-height {
  height: 269px;
}
.top-sell {
  .sell-icon {
    width: 50px;
    height: 50px;
    top: 40px;
    font-size: 25px;
    border-radius: 8px;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    // background-color: #f6c4ad;
  }
  .product-title {
    font-size: 18px;
    font-weight: 600;
    color: #6c757d;
    margin-left: 20px;
  }
  .product-price {
    margin-left: 20px;
  }
  .sell-price {
    float: right;
  }
}

@media screen and (max-width: 1169px) {
  .horizontal-layout {
    .sidebar {
      display: block;
    }

    .top-sidebar {
      display: none;
    }

    section.content {
      margin-top: 100px;
    }
  }
}

@media screen and (min-width: 1400px) {
  .boxed-layout .container {
    width: 1370px;
    max-width: 100%;
  }
}
