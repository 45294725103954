﻿/*
 *  Document   : _inputformgroup.scss
 *  Author     : RedStar Template
 *  Description: This scss file for input form style classes
 */
%extend_check {
  font-size: 13px;
  line-height: 1.42857;
  color: #414244;
  font-weight: 400;
}

.input-group {
  width: 100%;
  margin-bottom: 20px;
  display: table;

  .form-line {
    display: inline-block;
    width: 100%;
    //border-bottom: 1px solid #ddd;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 0px;
      @include transform(scaleX(0));
      @include transition(0.25s ease-in);
      border-bottom: 2px solid #1f91f3;
    }

    +.input-group-addon {
      padding-right: 0;
      padding-left: 10px;
    }
  }

  .help-info {
    float: right;
    font-size: 12px;
    margin-top: 5px;
    color: #999;
  }

  label.error {
    font-size: 12px;
    display: block;
    margin-top: 5px;
    font-weight: normal;
    color: #f44336;
  }

  .form-line.error {
    &:after {
      border-bottom: 2px solid #f44336;
    }
  }

  .form-line.success {
    &:after {
      border-bottom: 2px solid #4caf50;
    }
  }

  .form-line.warning {
    &:after {
      border-bottom: 2px solid #ffc107;
    }
  }

  .form-line.focused {
    &:after {
      @include transform(scaleX(1));
    }

    .form-label {
      bottom: 25px;
      left: 0;
      font-size: 12px;
    }
  }

  .input-group-addon {
    border: none;
    background-color: transparent;
    padding-left: 0;
    font-weight: bold;
    display: table-cell;

    .material-icons {
      font-size: 18px;
      color: #555;
    }
  }

  input[type="text"],
  .form-control {
    //border: none;
    box-shadow: none;
    padding-left: 0;
    margin: 0;
    font-size: 13px;
  }

  .form-control {
    &:focus {
      @include box-shadow(none !important);
    }
  }
}

.input-group.input-group-sm {
  .input-group-addon {
    i {
      font-size: 14px;
    }
  }

  .form-control {
    font-size: 12px !important;
  }
}

.input-group.input-group-lg {
  .input-group-addon {
    i {
      font-size: 26px;
    }
  }

  .form-control {
    font-size: 18px !important;
  }
}

.input-field {
  >label:not(.label-icon).active {
    font-size: 15px !important;
    transform: translateY(-5px) scale(0.8);
  }

  input,
  textarea {
    font-size: 13px !important;
  }

  >label {
    font-size: 13px !important;
  }
}

.form-control-label {
  text-align: right;

  label {
    margin-top: 8px;
  }
}

.form-horizontal {
  .form-group {
    margin-bottom: 0;
  }
}

.form-group {
  width: 100%;
  margin-bottom: 25px;

  .form-control {
    width: 100%;
    /*    border: none; */
    box-shadow: none;
    /*    border-bottom: 1px solid #9e9e9e;*/
    @include border-radius(0);
    padding-left: 0;
  }

  input.form-control {
    margin: 0;
    font-size: 13px;
  }

  .help-info {
    float: right;
    font-size: 12px;
    margin-top: 5px;
    color: #999;
  }

  label.error {
    font-size: 12px;
    display: block;
    margin-top: 5px;
    font-weight: normal;
    color: #f44336;
  }

  .form-line {
    width: 100%;
    position: relative;
    //border-bottom: 1px solid #ddd;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 0;
      bottom: -1px;
      @include transform(scaleX(0));
      @include transition(0.25s ease-in);
      border-bottom: 2px solid #1f91f3;
    }

    .form-label {
      font-weight: normal;
      color: #aaa;
      position: absolute;
      top: 10px;
      left: 0;
      cursor: text;
      @include transition(0.2s);
    }
  }

  .form-line.error {
    &:after {
      border-bottom: 2px solid #f44336;
    }
  }

  .form-line.success {
    &:after {
      border-bottom: 2px solid #4caf50;
    }
  }

  .form-line.warning {
    &:after {
      border-bottom: 2px solid #ffc107;
    }
  }

  .form-line.focused {
    &:after {
      @include transform(scaleX(1));
    }

    .form-label {
      top: -10px;
      left: 0;
      font-size: 12px;
    }
  }
}

.form-group-sm {
  .form-label {
    font-size: 12px;
  }

  .form-line.focused {
    .form-label {
      bottom: 20px;
      font-size: 10px;
    }
  }
}

.form-group-lg {
  .form-label {
    font-size: 18px;
  }

  .form-line.focused {
    .form-label {
      bottom: 35px;
      font-size: 12px;
    }
  }
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: transparent;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.show>.dropdown-menu {
  display: block;
}

.spinner {
  .input-group-addon {
    /*position: relative;*/
    position: absolute;
    top: 10px;
    right: 0;

    .spin-up i {
      position: absolute;
      left: 0;
    }

    .spin-down i {
      position: absolute;
      left: 0;
      bottom: -15px;
    }
  }
}

.bootstrap-select {
  .btn {
    color: #333;
  }
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: table-cell;
}

.form-check {
  @extend %extend_check;
  margin-bottom: 0.5rem;
  padding-left: 0;

  .form-check-label {
    cursor: pointer;
    padding-left: 25px;
    position: relative;
    padding-right: 15px;

    span {
      display: block;
      position: absolute;
      left: -1px;
      top: -1px;
      transition-duration: 0.2s;
      padding-left: 0;
    }
  }

  .form-check-input {
    opacity: 0;
    height: 0;
    width: 0;
    overflow: hidden;
    position: absolute;
    margin: 0;
    z-index: -1;
    left: 0;
    pointer-events: none;

    &:checked+.form-check-sign {
      &:before {
        animation: rippleOn 0.5s;
      }

      .check {
        background: #9c27b0;

        &:before {
          color: #ffffff;
          box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px,
            0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
          animation: checkboxOn 0.3s forwards;
        }
      }
    }
  }

  .form-check-sign {
    vertical-align: middle;
    position: relative;
    top: -2px;
    float: left;
    padding-right: 10px;
    display: inline-block;

    &:before {
      display: block;
      position: absolute;
      left: 0;
      content: "";
      background-color: rgba(0, 0, 0, 0.84);
      height: 20px;
      width: 20px;
      border-radius: 100%;
      z-index: 1;
      opacity: 0;
      margin: 0;
      top: 0;
      transform: scale3d(2.3, 2.3, 1);
    }

    .check {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 1px solid rgba(0, 0, 0, 0.54);
      overflow: hidden;
      z-index: 1;
      border-radius: 3px;

      &:before {
        position: absolute;
        content: "";
        transform: rotate(45deg);
        display: block;
        margin-top: -3px;
        margin-left: 7px;
        width: 0;
        color: #ffffff;
        height: 0;
        animation: checkboxOff 0.3s forwards;
      }
    }
  }
}

label {
  @extend %extend_check;
}

.default-select {
  select {
    width: 100px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }

  .select-wrapper input.select-dropdown {
    display: none;
  }
}
