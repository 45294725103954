/*
 *  Document   : _profile.scss
 *  Author     : RedStar Template
 *  Description: This scss file for profile page style classes
 */
.profile-tab-box {
  background: $white;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 15px;
  width: 100%;
}
.skill-progress {
  height: 10px !important;
}
.tab-all a {
  color: #948f8f !important;
  &.active {
    background-color: #e91e63 !important;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2),
      0 13px 24px -11px rgba(233, 30, 99, 0.6);
    color: #ffffff !important;
    border-radius: 30px;
  }
}


.step-tab-items {
	.step-item {
		position: relative;
		list-style: none;
		border-radius: 0%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #000;
		text-transform: capitalize;
		cursor: pointer;
		span {
			min-width: 26px;
			max-width: 26px;
			height: 26px;
			border: 1px solid #9f9f9f;
			color: #9f9f9f;
			display: inline-flex;
			vertical-align: middle;
			margin-right: 7px;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			font-size: 14px;
			font-weight: 700;
		}
		&.active {
			color: #000!important;
			span {
				color: #000;
				border-color: #000;
			}
		}
	}
}
// .step-tabs {
// 	.step-tab {
// 		display: none;
// 		&.active {
// 			display: block;
// 		}
// 	}
// }
.step-form-area {
	.steps {
		display: flex;
		align-items: self-start;
		padding: 50px 0;
		.steps-left {
			min-width: 200px;
			max-width: 200px;
			box-sizing: border-box;
			padding: 15px;
			background-color: rgb(247 247 247 / 92%);
			.step-tab-items {
				margin: 0;
				padding: 0;
				list-style: none;
				li {
					display: block;
					margin-bottom: 10px;
					font-size: 16px;
					font-weight: 500;
					text-transform: capitalize;
					color: #9f9f9f;
				}
			}
		}
		.step-tabs {
			width: 100%;
			box-sizing: border-box;
			padding-left: 34px;
			background-color: rgb(247 247 247 / 92%);
			padding-right: 30px;
			padding-bottom: 30px;
		}
	}
	.step-tabs-body-innerbox {
		background-color: #fff;
		box-sizing: border-box;
		padding: 24px;
		border-radius: 10px;
		box-shadow: 0px 0px 5px rgb(0 0 0 / 2%);
		margin-bottom: 24px;
		h5 {
			font-size: 16px;
			color: #000;
			font-weight: 400;
			margin-bottom: 15px;
			span {
				color: #c9c9c9;
			}
		}
		.steptopbox {
			margin-top: 24px;
		}
	}
}

	.step-form-area {
		.step-tabs-body {
			h4 {
				font-size: 20px;
				text-transform: capitalize;
				font-weight: 600;
				border-bottom: 1px solid #dbdbdb;
				padding: 10px 0px;
				margin-bottom: 24px;
			}
		}
	}

